import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import BackgroundImage from "gatsby-background-image";

export default class SinglePost extends React.Component {
  render() {
    const { posts } = this.props

    return (
      <div className="blog__list">
        {posts.map(({node}) => (
          <div key={`post-${node.id}`} className="card-article">
            <Link className="card-article__link"to={`/blog/${node.slug}`}>
              {node.title}
            </Link>

            {(node.featured_media !== null && node.featured_media.localFile !== null) ?
              <div className="card-article__cover">
                <BackgroundImage Tag="div"
                                 className="card-article__cover-img"
                                 fluid={node.featured_media.localFile.childImageSharp.fluid}>
                </BackgroundImage>
              </div>
              :
              <div className="card-article__cover card-article__cover--default">
                <div className="card-article__cover-img">
                </div>
              </div>
            }

            <h2 className="card-article__title" dangerouslySetInnerHTML={{ __html: node.title }}>
            </h2>
          </div>
        ))}
      </div>
    )
  }
}

SinglePost.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    title
    slug
    content
    featured_media {
      localFile {
        childImageSharp {
          fluid(maxWidth: 3000, maxHeight: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    acf {
      gallery {
        localFile {
          childImageSharp {
            fluid(maxWidth: 3000, maxHeight: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
