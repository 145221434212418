import React from 'react'
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'


const BackgroundSection = ({ className, info }) => (
  <BackgroundImage
    Tag={`div`}
    className={className}
    fluid={info.image}
  >
    <h2>{info.title}</h2>
  </BackgroundImage>
)

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

export default StyledBackgroundSection
