import React, {Component} from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/pages/home.scss"
import {graphql, Link} from "gatsby";
import Img from "gatsby-image";
import PostList from "../components/partials/single-post";
import StyledBackgroundSection from "../components/partials/StyledBackgroundSection";

class HomePage extends Component {
  render() {

    const { data } = this.props;
    // set static front page images
    const indexImg1 = data.allFile.edges[0].node.childImageSharp.fluid,
          indexImg2 = data.allFile.edges[1].node.childImageSharp.fluid;
    // console.log(data.allFile);
    const currentPage = data.allWordpressPage.edges[0].node;
    const { edges: posts } = data.allWordpressPost;

    const bgSection1 = {'image' : indexImg1, 'title' : 'Rally'}
    const bgSection2 = {'image' : indexImg2, 'title' : 'Landscape'}

    return (
      <Layout>
        <SEO title={currentPage.title} description="Photography, Movies articles, travel impressions"/>
        <div className="index">
          <div className="index__intro">
            <div className="index__intro-col">
              <div className="index__intro-background">
                {currentPage.acf.gallery !== null ?
                  currentPage.acf.gallery.map(galleryImg => (
                    <div key={galleryImg.localFile.id} className="index__intro-img">
                      <Img fluid={galleryImg.localFile.childImageSharp.fluid} />
                    </div>
                  ))
                  :
                  ''
                }
              </div>
            </div>

            <div className="index__intro-col">
              <div className="index__intro-slogans">
                <p className="index__slogan"
                   data-aos="fade-left"
                   data-aos-delay="30"
                   data-aos-easing="ease-in-out-cubic">Photographer</p>
                <p className="index__slogan"
                   data-aos="fade-left"
                   data-aos-delay="600"
                   data-aos-easing="ease-in-out-cubic">Front-end developer</p>
                <p className="index__slogan"
                   data-aos="fade-left"
                   data-aos-delay="900"
                   data-aos-easing="ease-in-out-cubic">Explorer</p>
              </div>
            </div>

          </div>

          <StyledBackgroundSection className="index__cover index__cover--static" info={bgSection1}/>

          <div className="index__articles">
            <div className="container">
              <h3 className="index__subtitle">Check the latest articles:</h3>

              <div className="index__articles-list">
                <PostList posts={posts} />
              </div>

              <div className="index__info-more">
                <Link className="link" to="/blog">
                  Read more articles
                </Link>
              </div>
            </div>
          </div>

          <StyledBackgroundSection className="index__cover index__cover--static" info={bgSection2}/>

        </div>
      </Layout>
    )
  }
}

export default HomePage

export const pageQuery = graphql`
  query indexQuery {
    allWordpressPage(filter: {slug: {eq: "home"}}) {
       edges {
          node {
            title
            content
            acf {
                gallery {
                  localFile {
                    id
                    childImageSharp {
                      fluid(quality: 95, maxWidth: 6000) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
          }
       }
    }
    allWordpressPost(limit: 3) {
      edges {
        node {
           ...PostListFields
        }
      }
    }
    allFile(filter: {relativePath: {regex: "/index/"}}) {
        edges {
          node {
            name
            childImageSharp {
              fluid(quality: 95, maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
     }
  }
`
